.r-video-msg-part .r-video-right {
  border-left: solid 3px #F6CC51; }

.r-car-search .r-top-form-title h3 {
  color: #F6CC51; }

.r-car-option-tab li.nav-item a.nav-link.active, .r-car-option-tab li.nav-item a.nav-link:hover {
  background-color: #F6CC51;
  border-color: #F6CC51; }

.r-faq-option-tab li.nav-item a.nav-link.active i, .r-faq-option-tab li.nav-item a.nav-link:hover i {
  color: #F6CC51; }

.r-header .r-header-strip {
  background: #F6CC51; }

.r-form-strip::before {
  background: #F6CC51; }

.r-car-tabs > .nav li a.active {
  border-bottom-color: #F6CC51; }

.r-slider-serach.r-slider-serach-down > form .form-title h2::after {
  background-color: #F6CC51; }

.r-sub-banners .r-sub-banner-in h4 {
  color: #F6CC51; }

.r-to-top:hover {
  border-color: #F6CC51;
  color: #F6CC51; }

.r-car-search .r-top-form-title h3::after {
  background-color: #F6CC51; }

.btn-full, .r-best-offer-list .r-best-offer-single .r-offer-rewst-this, .iw-content .r-best-offer-single .r-offer-rewst-this {
  background: #F6CC51; }

.r-sub-banners .r-sub-banner-in.r-sub-accent {
  background-color: #F6CC51; }

.iw-content .r-best-offer-single .r-offer-img .r-offer-img-over i {
  background: #F6CC51; }

.r-footer .r-footer-block .r-footer-phone span {
  color: #F6CC51; }

.r-insurance-part .r-insurance-box:hover {
  background: #F6CC51; }

.r-get-in-touch .r-get-header h2 {
  color: #F6CC51; }

.r-get-in-touch .r-get-form .text-center button {
  background: #F6CC51; }

.r-counter-section.r-counter-with-bg {
  background-color: #F6CC51; }
  .r-counter-section.r-counter-with-bg div[class*="col-"] {
    border-right-color: #F6CC51; }

.r-about-info .r-about-info-content ul li i {
  color: #F6CC51; }

.r-who-royal .r-about-info-content .r-sec-head h2 {
  color: #F6CC51; }

.r-who-royal .r-about-info-content ul li i {
  color: #F6CC51; }

.r-newsletter-suscribe .r-newsletter-form i {
  color: #F6CC51; }

.r-best-vehicles .r-best-vehicle-list .r-best-vehicle-single .r-best-vehicle-content .r-modal-name:hover {
  color: #F6CC51; }

.r-best-vehicles .r-best-vehicle-list .r-best-vehicle-single .r-best-vehicle-content .r-modal-name span {
  color: #F6CC51; }

.r-best-vehicles .r-best-vehicle-list .r-best-vehicle-single .r-best-vehicle-content .r-average {
  background-color: #F6CC51; }

.r-best-vehicles .r-best-leftbar .r-best-vehicle-types {
  background-color: #F6CC51; }
  .r-best-vehicles .r-best-leftbar .r-best-vehicle-types li span {
    border-bottom: 1px solid #F6CC51; }
    .r-best-vehicles .r-best-leftbar .r-best-vehicle-types li span::before {
      background-color: #F6CC51; }
  .r-best-vehicles .r-best-leftbar .r-best-vehicle-types li::after {
    background-color: #F6CC51; }

.r-best-offer-list .r-best-offer-single .r-offer-img .r-offer-img-over i {
  background-color: #F6CC51; }

.r-best-offer-list .r-best-offer-single .r-best-offer-content > a {
  color: #F6CC51; }
  .r-best-offer-list .r-best-offer-single .r-best-offer-content > a:hover {
    color: #F6CC51; }
    .r-best-offer-list .r-best-offer-single .r-best-offer-content > a:hover b {
      color: #F6CC51; }

.r-car-info-wrapper .r-car-info-header .r-car-rating i {
  color: #F6CC51; }

.r-car-info-wrapper .r-car-info-header .r-car-name span {
  color: #F6CC51; }

.r-car-info-wrapper .r-car-info-header .r-offer-cost {
  background: #F6CC51; }

.r-car-product-carousel-wrapper .r-car-product-carousel-thumb li.active {
  border-color: #F6CC51; }

.r-car-whole-info .r-car-info-content .r-product-name span {
  color: #F6CC51; }

.r-product-testimonial-wrapper {
  background: #F6CC51; }

.r-product-discount .r-discount-content {
  color: #F6CC51; }

.r-site-default-accordion .r-accordion-body .input-group .form-control {
  border-left-color: #F6CC51; }

.r-site-checkbox label input:checked + .r-site-checkbox-icon {
  border-color: #F6CC51; }

.r-site-checkbox label .r-site-checkbox-icon i {
  color: #F6CC51; }

.r-car-filter-area .r-change-view li a {
  color: #F6CC51; }

.r-best-vehicles .r-best-leftbar .r-best-vehicle-types.r-best-vehicle-category li i {
  color: #F6CC51; }

.r-hide-car-list:hover {
  background: #F6CC51; }

.btn-full {
  background: #F6CC51; }

.btn-primary {
  background: #F6CC51; }

.btn-default:hover {
  background: #F6CC51; }

.r-sec-head.r-sec-head-line h2::after {
  background-color: #F6CC51; }

.r-contact-part .r-contact-address .address-cnt i {
  color: #F6CC51; }

.r-driver-blog .r-drivers .r-drivers-detail .name {
  border-left: solid 3px #F6CC51; }

.r-driver-blog .r-drivers .r-drivers-detail .icon a:hover {
  color: #F6CC51; }

.r-driver-blog-dark .r-sec-head h2 {
  color: #F6CC51; }

.r-faq-header h2::before {
  background: #F6CC51; }

.r-accordion .r-accordion-body h3 {
  color: #F6CC51; }

.r-footer .r-footer-block .r-newsletter button {
  background: #F6CC51; }

.r-footer .r-footer-block .r-footer-nav ul li::before {
  color: #F6CC51; }

.r-footer .r-footer-block .r-footer-nav ul li a:hover {
  color: #F6CC51; }

.r-footer .r-footer-block .r-footer-map a {
  background: #F6CC51; }

.r-footer .r-footer-strip ul li a:hover {
  color: #F6CC51; }

.r-footer-dark .r-footer-block .r-footer-phone h5 {
  color: #F6CC51; }

.r-footer-dark .r-location-map a i {
  color: #F6CC51; }

.r-footer-dark .r-location-map a:hover {
  color: #F6CC51; }

.r-footer-dark .r-footer-strip .r-copy-right-text strong {
  color: #F6CC51; }

.r-scroll-up-icon {
  background: #F6CC51; }

.r-gallery-part ul li .gallery-hover {
  background: #F6CC51; }

.r-gallery-part ul li .gallery-text .icon-gallery i {
  color: #F6CC51; }

.r-header .r-header-strip .r-nav-section nav > ul > li.r-has-child > ul > li > a:hover {
  background-color: #F6CC51; }

.r-header .r-header-inner-banner .r-breadcrum ul li a:hover {
  color: #F6CC51; }

.r-header.r-header-strip-01 .r-nav-section nav ul li a:hover {
  color: #F6CC51; }

.r-header.r-header-strip-02 .r-nav-section nav ul li a:hover {
  color: #F6CC51; }

.r-latest-news .r-sec-head h2 {
  color: #F6CC51; }

.r-latest-news-content {
  text-transform: uppercase; }
  .r-latest-news-content h4 {
    border-left: 2px solid #F6CC51; }
    .r-latest-news-content h4 a {
      color: #F6CC51; }
  .r-latest-news-content .r-read-more:hover {
    color: #F6CC51; }

.r-follow-us span a {
  color: #F6CC51; }

.r-latest-news-light .r-latest-news-content .r-date {
  color: #F6CC51; }

.r-latest-news-light .r-latest-news-content h4 a:hover {
  color: #F6CC51; }

.r-latest-news-light .r-latest-news-content .r-read-more:hover {
  color: #F6CC51; }

.r-login-register .r-login-register-in .r-auth-or {
  background-color: #F6CC51; }
  .r-login-register .r-login-register-in .r-auth-or span {
    background: #F6CC51; }

.iw-content .r-best-offer-single .r-best-offer-content > a {
  color: #F6CC51; }
  .iw-content .r-best-offer-single .r-best-offer-content > a:hover {
    color: #F6CC51; }
    .iw-content .r-best-offer-single .r-best-offer-content > a:hover b {
      color: #F6CC51; }

.r-slider-serach.form-search .form-title h2 {
  color: #F6CC51; }

.r-slider-serach > form .row .form-group input:focus {
  border-color: #F6CC51; }

.r-carlist-search .r-search-full .fa {
  background-color: #F6CC51; }

.r-advantages-part .r-advantage-main-part .r-advantages-box .icon {
  color: #F6CC51; }

.r-advantages-part .r-advantage-main-part .r-advantages-box:hover {
  background: #F6CC51; }

.r-advantages-part .r-what-offers .r-offers-box .icon {
  color: #F6CC51; }

.r-advantages-part .r-advantage-main-part .r-advantages-box .icon {
  color: #F6CC51; }

.r-advantages-part .r-advantage-main-part .r-advantages-box:hover {
  background: #F6CC51; }

.r-advantages-part .r-what-offers .r-offers-box .icon {
  color: #F6CC51; }

.r-new-member .r-sec-head h2 {
  color: #F6CC51; }

.r-new-member .r-discounted-car .r-discount-single .r-discount-content .r-starts li i {
  color: #F6CC51; }

.r-new-member .r-discounted-car .r-discount-single h4 a:hover {
  color: #F6CC51; }

.r-new-member .r-discounted-car .r-discount-single ul.r-car-point li:hover {
  background-color: #F6CC51; }

.r-slider-item .r-slider-top-content h1 span {
  color: #F6CC51; }

.r-slider-item .r-slider-top-content .btn-outlined:hover {
  background: #F6CC51;
  border-color: #F6CC51; }

.r-slider .owl-dots .owl-dot.active {
  border-bottom: 2px solid #F6CC51; }

.r-header .r-header-action a:hover {
  background: #F6CC51; }

.iw-content .r-best-offer-single ul li:hover {
  background-color: #F6CC51; }

.r-best-offer-list .r-best-offer-single ul li:hover {
  background-color: #F6CC51; }
